import moment from 'moment'

import AppearanceStep from '../../edit_steps/geobooster_widgets_appearance'
import required from '../../validators/required'
import equalPrimitiveObjects from '../../utils/equalPrimitiveObjects'

export default {
  components: {
    AppearanceStep
  },
  data: () => ({
    steps: {
      appearance: {
        theme: {
          component: 'Radio',
          name: 'theme',
          value: 'light',
          values: [
            {
              text: 'Light mode',
              value: 'light',
              icon: 'far fa-sun'
            },
            {
              text: 'Dark mode',
              value: 'dark',
              icon: 'far fa-moon'
            },
            {
              text: 'Custom',
              value: 'custom',
              icon: 'far fa-sliders-h'
            }
          ],
          label: 'Theme',
          error: false,
          errorText: ''
        },
        font: {
          component: 'CustomSelect',
          name: 'font',
          value: 'Arial',
          values: [
            {
              text: 'Arial',
              value: 'Arial'
            }
          ],
          label: 'Font',
          error: false,
          errorText: '',
          disabled: true
        },
        backgroundColor: {
          component: 'ColorPicker',
          name: 'backgroundColor',
          value: '#FFFFFF',
          defaultValue: '#FFFFFF',
          label: 'Background color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        momentCardBgColor: {
          component: 'ColorPicker',
          name: 'momentCardBgColor',
          value: '#FFFFFF',
          defaultValue: '#FFFFFF',
          label: 'Card moment background color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        borderColor: {
          component: 'ColorPicker',
          name: 'borderColor',
          value: '#DCDCDC',
          defaultValue: '#DCDCDC',
          label: 'Border color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        customerNameColor: {
          component: 'ColorPicker',
          name: 'customerNameColor',
          value: '#377DFF',
          defaultValue: '#377DFF',
          label: 'Customer color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        textColor: {
          component: 'ColorPicker',
          name: 'textColor',
          value: '#3D454D',
          defaultValue: '#3D454D',
          label: 'Text color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        dateColor: {
          component: 'ColorPicker',
          name: 'textColor',
          value: '#B1B1B1',
          defaultValue: '#B1B1B1',
          label: 'Date color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        widgetLayout: {
          component: 'Radio',
          name: 'widgetLayout',
          value: 'full',
          values: [
            {
              text: 'Default',
              value: 'full'
            },
            {
              text: 'Map only',
              value: 'map'
            },
            {
              text: 'No map',
              value: 'no_map'
            }
          ],
          label: 'Widget layout',
          error: false,
          errorText: ''
        },
        momentListLayout: {
          component: 'Radio',
          name: 'momentListLayout',
          value: 'tile',
          defaultValue: 'tile',
          values: [
            {
              text: 'Media Tiles',
              value: 'tile',
              icon: 'fa-regular fa-grid-2'
            },
            {
              text: 'Media Slider',
              value: 'slider',
              icon: 'fa-regular fa-gallery-thumbnails'
            }
          ],
          label: 'Moments list layout',
          error: false,
          errorText: ''
        },
        momentCardLayout: {
          component: 'Radio',
          name: 'momentCardLayout',
          value: 'tile',
          defaultValue: 'tile',
          values: [
            {
              text: 'Default',
              value: 'tile'
            },
            {
              text: 'Match heights',
              value: 'slider'
            }
          ],
          label: 'Card moment layout',
          error: false,
          errorText: ''
        },
        momentCardCssClass: {
          component: 'Radio',
          name: 'momentCardCssClass',
          value: 'preview-small',
          defaultValue: 'preview-small',
          values: [
            {
              text: 'Small',
              value: 'preview-small'
            },
            {
              text: 'Medium',
              value: 'preview-medium'
            },
            {
              text: 'Large',
              value: 'preview-large'
            }
          ],
          label: 'Media preview size',
          error: false,
          errorText: ''
        },
        dateFormat: {
          component: 'CustomSelect',
          name: 'dateFormat',
          value: 'MMM DD, YYYY',
          defaultValue: 'MMM DD, YYYY',
          values: [
            {
              text: 'Oct 27, 2021',
              value: 'MMM DD, YYYY'
            },
            // => "17.02.2023"
            {
              text: '27.10.2021',
              value: 'DD.MM.YYYY'
            },
            // => "02/17/2023"
            {
              text: '10/27/2021',
              value: 'MM/DD/YYYY'
            },
            // => "02.17.2023"
            {
              text: '10.27.2021',
              value: 'MM.DD.YYYY'
            },
            // => "17.02.2023 19:30"
            {
              text: '27.10.2021 19:30',
              value: 'DD.MM.YYYY HH:mm'
            },
            // => "02.17.2023 07:30 PM"
            {
              text: '10.27.2021 07:30 PM',
              value: 'MM.DD.YYYY hh:MM A'
            }
          ],
          label: 'Date format',
          error: false,
          errorText: ''
        },
        categoryTextColor: {
          component: 'ColorPicker',
          name: 'categoryTextColor',
          value: '#3D454D',
          defaultValue: '#3D454D',
          label: 'Category text color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        categoryBgColor: {
          component: 'ColorPicker',
          name: 'categoryBgColor',
          value: '#EDF0F4',
          defaultValue: '#EDF0F4',
          label: 'Category bg color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        mapMarkerColor: {
          component: 'ColorPicker',
          name: 'mapMarkerColor',
          value: '#0942D3',
          defaultValue: '#0942D3',
          label: 'Map marker color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        mapMarkerIcon: {
          component: 'CustomSelect',
          name: 'mapMarkerIcon',
          value: 'flag',
          values: [
            {
              text: 'Flag',
              value: 'flag',
              icon: 'fa-solid fa-flag text-primary'
            },
            {
              text: 'House',
              value: 'house',
              icon: 'fa-solid fa-house text-primary'
            },
            {
              text: 'Garage',
              value: 'garage',
              icon: 'fa-solid fa-garage text-primary'
            },
            {
              text: 'Star',
              value: 'star',
              icon: 'fa-solid fa-star text-primary'
            },
            {
              text: 'Plunger',
              value: 'plunger',
              icon: 'fa-solid fa-sink text-primary'
            },
            {
              text: 'Pin',
              value: 'google',
              icon: 'fa-solid fa-location-dot text-primary'
            },
            {
              text: 'Custom',
              value: 'custom',
              icon: 'fa-regular fa-symbols text-primary'
            }
          ],
          label: 'Map marker icon',
          error: false,
          errorText: ''
        },
        customMapMarkerIcon: {
          component: 'MapMarkerSelect',
          name: 'customMapMarkerIcon',
          value: null,
          error: false,
          errorText: '',
          validationFields: ['mapMarkerIcon'],
          validators: [
            (value, { mapMarkerIcon }) => {
              if (mapMarkerIcon.value === 'custom' && !value?.url) {
                return {
                  error: true,
                  errorMessage: 'Please upload a map marker image or select non-custom icon preset'
                }
              }

              return { error: false }
            }
          ]
        }
      }
    },
    darkTheme: {
      backgroundColor: '#2C2C2C',
      momentCardBgColor: '#676060',
      borderColor: '#FFFFFF',
      customerNameColor: '#758FDC',
      textColor: '#FFFFFF',
      dateColor: '#C9C5C5',
      mapMarkerColor: '#000000',
      categoryTextColor: '#3D454D',
      categoryBgColor: '#EDF0F4'
    },
    lightTheme: {
      backgroundColor: '#FFFFFF',
      momentCardBgColor: '#FFFFFF',
      borderColor: '#DCDCDC',
      customerNameColor: '#377DFF',
      textColor: '#3D454D',
      dateColor: '#B1B1B1',
      mapMarkerColor: '#0942D3',
      categoryTextColor: '#3D454D',
      categoryBgColor: '#EDF0F4'
    },
    customTheme: {
      backgroundColor: '#FFFFFF',
      momentCardBgColor: '#FFFFFF',
      borderColor: '#DCDCDC',
      customerNameColor: '#377DFF',
      textColor: '#3D454D',
      dateColor: '#B1B1B1',
      mapMarkerColor: '#0942D3',
      categoryTextColor: '#3D454D',
      categoryBgColor: '#EDF0F4'
    }
  }),
  methods: {
    checkForDefaultValue(key) {
      return this.steps.appearance[key].value === this.steps.appearance[key].defaultValue
    },
    getValueIfNotDefault(key) {
      return this.checkForDefaultValue(key) ? undefined : this.steps.appearance[key].value
    },
    setTheme(theme) {
      const iterablePalette = this[`${theme}Theme`]

      Object.keys(iterablePalette).forEach((key) => {
        const color = iterablePalette[key]
        this.steps.appearance[key].value = color
        this.steps.appearance[key].defaultValue = color
      })
    },
    setDateFormatSelectValues() {
      const dateFormats = this.steps.appearance.dateFormat.values
      dateFormats.forEach((value, index) => {
        dateFormats[index].text = moment().format(value.value)
      })
    }
  },
  computed: {
    palette() {
      return {
        backgroundColor: this.steps.appearance.backgroundColor.value.toUpperCase(),
        momentCardBgColor: this.steps.appearance.momentCardBgColor.value.toUpperCase(),
        borderColor: this.steps.appearance.borderColor.value.toUpperCase(),
        customerNameColor: this.steps.appearance.customerNameColor.value.toUpperCase(),
        textColor: this.steps.appearance.textColor.value.toUpperCase(),
        dateColor: this.steps.appearance.dateColor.value.toUpperCase(),
        mapMarkerColor: this.steps.appearance.mapMarkerColor.value.toUpperCase(),
        categoryTextColor: this.steps.appearance.categoryTextColor.value.toUpperCase(),
        categoryBgColor: this.steps.appearance.categoryBgColor.value.toUpperCase()
      }
    },
    activeTheme() {
      const { lightTheme, darkTheme, palette: activeTheme } = this

      if (equalPrimitiveObjects(lightTheme, activeTheme)) {
        return 'light'
      }

      if (equalPrimitiveObjects(darkTheme, activeTheme)) {
        return 'dark'
      }

      return 'custom'
    }
  },
  watch: {
    'steps.appearance.theme.value': {
      handler(newVal) {
        if (newVal !== this.activeTheme) {
          this.setTheme(newVal)
        }
      }
    },
    activeTheme(newVal) {
      this.steps.appearance.theme.value = newVal
    }
  },
  mounted() {
    this.setDateFormatSelectValues()
  }
}
