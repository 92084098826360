<template>
  <div class="custom-map-marker-select">
    <p class="custom-map-marker-select__text">
      Select a square PNG image with a transparent background.
      The size should be between 128х128 and 1024х1024 px.
      Non-square images may differ from the preview shown below.
    </p>
    <div class="custom-map-marker-select__wrapper">
      <div class="custom-map-marker-select__preview">
        <svg
          class="custom-map-marker-select__balloon"
          width="250" height="310" viewBox="0 0 250 310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            :fill="mapMarkerColor"
            d="M250 125.531C250 223.829 125 309 125 309C125 309 0 223.829 0 125.531C0 56.2023 55.9644 0 125 0C194.036 0 250 56.2023 250 125.531Z" />
          <circle cx="125" cy="125" r="117" fill="white" />
        </svg>

        <img v-if="image?.url"
          class="custom-map-marker-select__image"
          :src="image.url"
          alt="Custom map marker image">
        <span v-else class="custom-map-marker-select__sample-text">Marker example</span>
      </div>
      <div class="custom-map-marker-select__buttons">
        <button class="btn btn-xs" @click="$refs.file.click()">
          <i class="fa-regular fa-download" />
          <input type="file"
            ref="file"
            hidden
            :accept="ACCEPT_TYPES"
            @change="onFileChange">
          <span class="pl-2">Upload new</span>
        </button>
        <button v-if="image?.url" class="btn btn-outline-danger btn-xs" @click="deleteImage">
          <i class="fa-regular fa-trash" />
          <span class="pl-2">Delete image</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const ACCEPT_TYPES = 'image/png'
export default {
  props: {
    value: { type: [Object, File], required: false, default: null },
    mapMarkerColor: { type: String, required: false, default: '#A5A6B5' }
  },
  data() {
    return {
      image: null
    }
  },
  created() {
    this.ACCEPT_TYPES = ACCEPT_TYPES
    this.image = this.value
  },
  methods: {
    onFileChange(e) {
      const { files } = e.target

      if (!files.length) {
        return
      }

      const wrongFormatFiles = []
      const filteredFiles = Array.from(files).filter(({ name, type }) => {
        if (ACCEPT_TYPES.includes(type)) return true

        wrongFormatFiles.push(name)
        return false
      })

      if (wrongFormatFiles.length > 0) {
        toastr.warning(`Can't upload files:<br>${wrongFormatFiles.join('<br>')}`, { timeOut: 2000 })
      }

      this.$refs.file.value = ''
      if (!/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = ''
        this.$refs.file.type = 'file'
      }

      this.createImage(filteredFiles[0])
    },
    createImage(file) {
      const vm = this
      const reader = new FileReader()
      reader.onload = (event) => {
        const imageUrl = event.target.result
        vm.image = { url: imageUrl, changed: true }
        this.$emit('input', vm.image)
      }
      reader.readAsDataURL(file)
    },
    deleteImage() {
      // todo: maybe add delete with swal
      this.image = null
      this.$emit('input', { url: null, changed: true })
    }
  }
}
</script>
