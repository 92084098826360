<template>
  <div v-if="searchTerm.geogrids.length > 1" :class="['geogrid-slider', { 'selecting-gg': selectedGeogridsIds.length > 0 }]">
    <div v-if="selectedGeogridsIds.length > 0" class="geogrid-comparing-contol">
      <span v-if="selectedGeogridsIds.length === 1">
        Select another Geogrid to compare with or
        <a :href="comparisonLastGeogridUrl" target="_blank" class="text-primary">
          compare with last geogrid
        </a>
      </span>
      <a v-else :href="comparisonUrl" target="_blank" class="btn btn-xs btn-primary">
        View comparison
      </a>

      <button type="button" class="btn btn-link gap-2" @click="clearGeogridSelection">
        <span class="font-size-12">Close comparison</span>
        <i class="far fa-times" />
      </button>
    </div>
    <div class="geogrid-slider-list">
      <button class="btn btn-link" @click="scrollGGList(-1)">
        <i class="far fa-chevron-left" />
      </button>
      <div class="position-relative scroll hor" ref="geogridListWrap">
        <div v-for="gg in searchTerm.geogrids"
          :key="gg.obfuscatedId"
          :class="['geogrid-slider-item', { active: geogrid.obfuscatedId === gg.obfuscatedId }]"
          :data-geogrid-id="gg.obfuscatedId"
          @click="updateGeogrid(gg)">
          <GeogridPreview :ranks="gg.ranks" />
          <span v-for="(dateLine, index) in getDateLines(gg.createdAtFormatted)" :key="`${dateLine}${index}`" class="datetime">{{ dateLine }}</span>

          <i
            :class="[
              'geogrid-slider-item-checkbox',
              `${isGeogridSelected(gg) ? 'fas fa-check' : 'far fa'}-square`,
              { disabled: isGeogridDisabled(gg) }
            ]"
            @click.stop="toggleGeogrid(gg)" />
        </div>
      </div>
      <button class="btn btn-link" @click="scrollGGList(1)">
        <i class="far fa-chevron-right" />
      </button>
    </div>
    <div v-if="showTooltip" class="geogrid-comparing-tooltip" @click.stop="hideTooltip">
      <div ref="tooltipCheckbox" class="fake-tooltip-checkbox">
        <div class="geogrid-comparing-bubble" @click.stop>
          <span>Select two Geogrid previews to enter comparison mode.</span>
          <i class="fa fa-times" @click.stop="hideTooltip" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import GeogridPreview from 'vue_widgets/geogrids/components/geogrid_preview'

const GEOGRID_TOOLTIP_KEY = 'geogrid_tooltip_key'

export default {
  name: 'Slider',
  components: { GeogridPreview },
  props: {
    isScheduledShow: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(['geogrid', 'searchTerm']),
    comparisonUrl() {
      const url = new URL(this.geogrid.comparisonUrl)

      this.selectedGeogridsIds.forEach((id) => {
        url.searchParams.append('ids[]', id)
      })

      return url.href
    },
    comparisonLastGeogridUrl() {
      const url = new URL(this.geogrid.comparisonUrl)

      return `${url.origin}/stats/geogrids/${this.selectedGeogridsIds[0]}/compare`
    }
  },
  data() {
    return {
      selectedGeogridsIds: [],
      showTooltip: false
    }
  },
  methods: {
    ...mapActions(['showGeogrid']),
    scrollGGList(k) {
      const { geogridListWrap } = this.$refs

      if (!geogridListWrap) return

      const childWidth = geogridListWrap.firstElementChild?.offsetWidth || 0
      const visibleChildCount = Math.floor(geogridListWrap.offsetWidth / childWidth)

      geogridListWrap.scrollTo({
        left: geogridListWrap.scrollLeft + childWidth * visibleChildCount * k,
        behavior: 'smooth'
      })
    },
    scrollToGeogrid() {
      const { geogridListWrap } = this.$refs

      if (!geogridListWrap) return

      const selectedElement = geogridListWrap.querySelector(`[data-geogrid-id="${this.geogrid.obfuscatedId}"]`)
      if (selectedElement) {
        geogridListWrap.scrollTo({
          left: selectedElement.offsetLeft
        })
      }
      this.updatedFromSlider = false
    },
    updateGeogrid(gg) {
      this.updatedFromSlider = true
      this.showGeogrid({ geogrid: gg })
    },
    toggleGeogrid(gg) {
      if (this.isGeogridDisabled(gg)) return

      const ggIndex = this.selectedGeogridsIds.indexOf(gg.obfuscatedId)
      if (ggIndex === -1) {
        this.selectedGeogridsIds.push(gg.obfuscatedId)
      } else {
        this.selectedGeogridsIds.splice(ggIndex, 1)
      }
    },
    clearGeogridSelection() {
      this.selectedGeogridsIds = []
    },
    isGeogridSelected(gg) {
      return this.selectedGeogridsIds.indexOf(gg.obfuscatedId) !== -1
    },
    isGeogridDisabled(gg) {
      return !this.isGeogridSelected(gg) && this.selectedGeogridsIds.length > 1
    },
    renderTooltip() {
      if (this.showTooltip) return

      this.showTooltip = !localStorage.getItem(GEOGRID_TOOLTIP_KEY)
      if (this.showTooltip) {
        this.$nextTick(() => {
          const { tooltipCheckbox } = this.$refs
          const { x, y } = document.querySelector('.geogrid-slider-item.active .geogrid-slider-item-checkbox').getBoundingClientRect()
          tooltipCheckbox.style.top = `${y}px`
          tooltipCheckbox.style.left = `${x}px`
          tooltipCheckbox.style.right = 'auto'

          localStorage.setItem(GEOGRID_TOOLTIP_KEY, 1)
        })
      }
    },
    hideTooltip() {
      this.showTooltip = false
    },
    getDateLines(date) {
      const jsDate = new Date(date)
      const currentYear = new Date().getFullYear()
      const [dateLine, timeLine] = moment(jsDate).format(`MMM D,${jsDate.getFullYear() !== currentYear ? ' YYYY' : ''}| hh:mm A`).split('|')
      return [dateLine, timeLine]
    }
  },
  watch: {
    'searchTerm.geogrids': {
      deep: true,
      handler() {
        this.clearGeogridSelection()
        if (this.searchTerm.geogrids.length > 1) {
          this.$nextTick(() => {
            this.scrollToGeogrid()
            if (document.querySelector('.geogrid-slider-item.active .geogrid-slider-item-checkbox')?.checkVisibility()) {
              this.renderTooltip()
            }
          })
        }
      }
    },
    isScheduledShow(val) {
      if (val) {
        this.$nextTick(() => {
          this.scrollToGeogrid()
          if (document.querySelector('.geogrid-slider-item.active .geogrid-slider-item-checkbox')?.checkVisibility()) {
            this.renderTooltip()
          }
        })
      }
    },
    geogrid: {
      deep: true,
      handler(val) {
        if (val && !this.updatedFromSlider) {
          this.$nextTick(() => {
            this.scrollToGeogrid()
          })
        }
      }
    }
  }
}
</script>
